import React, { useContext, useEffect } from "react";
import {
  initializeToken,
  initializeInspectionId,
  initializeretake,
  makeRequest,
  userLogs,
  patLogs,
  addUserAgentData,
  setLogging,
  startModel,
  setInspectionID,
  setConditionResume,
  setMandatoryResume,
  setSubConditionResume,
  setSubMandatoryResume,
  setResumeFlowAllow,
  setDamageCount,
  setWindShieldDamageCount,
  setRedisFlowSetFirstTime,
  initializeEmail,
  setResumeFlowCount
} from "../context/utils";
import { AppContext } from "../context";
import loading from "../img/loading.gif";
// import * as Sentry from "@sentry/react";
// import ReactGA from "react-ga4";
import MobileDetect from "mobile-detect";

let finalConditions = {};
let finalSubConditions = {};
let mandatoryConditions = {};
let subMandatoryConditions = {};
let startDateLog = new Date().getTime();
let endDateLog = 0;
let startdates = Date.now();
let userToken = undefined;
let userAgentData = "";
let windshiledModuleCount = false;
let damageModuleCount = false;
let fetchDataFromRedis = false;
let resumeFlowEmailAllow = true;
const Loading = () => {
  // ReactGA.send({
  //   hitType: "pageview",
  //   page: "Loading page",
  //   title: "Loading page",
  // });
  const {
    setCondition,
    setPhone,
    setScreen,
    setClientId,
    setInspectionId,
    inspectionId,
    setConfig,
    setMandatory,
    setSubMandatory,
    setSubCondition,
    setToken,
    setErrorMessage,
    setRetake,
    setCaseId,
    setCount,
    setRestrictUserIfallNotGreen,
    config,
    setUserEmail,
    setCurrentDamage
  } = useContext(AppContext);
  let hashData = window.location.hash.split("#");
  if (hashData.length > 1) {
    userToken = hashData[1];
  }
  if (hashData.length > 2) {
    userAgentData = hashData[2];
    addUserAgentData({ token: userToken, userAgentData: userAgentData });
  }

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  let isAndroid = /Android/.test(navigator.userAgent);
  let SetRetake_inspectionId;
  let reOpen;


  const loadCustomFont = (URL) => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      @font-face {
        font-family: 'D-DIN';
        src: url('${URL}') format('truetype');
      }
      body {
        font-family: 'D-DIN', sans-serif;
      }
    `;
    document.head.appendChild(style);
  };

  // const demo = {
  //   logo: "/images/logo.svg",
  //   expirationDate: 7,
  //   email: true,
  // "vmgFLowType": "single or both",
  // "vmgUrl": "",
  // "authorization": ""
  // "customHiibVmg": true
  //   rejectionOption: true,
  //   rejectionText: "rejection",
  //   rejectionCompleteText: "Now you can close the tab",
  //   rejectionRejectBtnTxt: "Reject",
  //   rejectionAgreeBtnTxt: "Continue",
  // loactionOptional: false,
  //   "restrictionforLocationPermission": true,
  //   "restrictionforLocationPermissionTxtForAndroid": "Location permission is denied. Please enable it for Chrome browser",
  //   "restrictionforLocationPermissionTxtForIos": "Location permission is denied. Please enable it for Safari browser",
  //   language: "English",

  // "imageFeedback": {
  // 	"ImageAnalysisMssg1": "Er is meer tijd nodig om de foto's te analyseren. Ga aub verder met de overige onderdelen",
  // 	"OtherBtnTxt": "Andere secties",
  // 	"ReasonTxt": "Reden",
  // 	"RecaptureBtnTxt": "Foto opnieuw maken",
  // 	"ImageAnalysisMssg2": "Wacht aub totdat de analyse is voltooid",
  // 	"recaptureMssg": "Er zijn maar weinig afbeeldingen verkeerd vastgelegd. Klik op de rode gedeelten om opnieuw op te nemen",
  // 	"ReasonMssg": {
  // 		"The image captured was blurry.": "De genomen foto is niet scherp",
  // 		"The image was captured in low light condition.": "De genomen foto is te donker",
  // 		"Please stand close to the car to capture the zoomed out image.": "Sta dichter bij de auto om de foto te maken",
  // 		"Please stand away from the car to capture the zoomed out image.": "Sta verder van de auto om de foto te maken",
  // 		"Please recapture the VIN to clearly show 17 digits.": "Neem de foto van de VIN opnieuw. Let op de VIN heeft 17 cijfers",
  // 		"You did not capture these parts in the image:": "Deze onderdelen zijn niet goed vastgelegd:",
  // 		"back bumper": "Achterbumper",
  // 		"front bumper": "Voorbumper",
  // 		"front door": "Voordeur",
  // 		"back door": "Achterdeur",
  // 		"dicky": "dicky",
  // 		"front glass": "Voorruit",
  // 		"back glass": "Achterruit",
  // 		"fender": "Voorscherm",
  // 		"qtr panel": "Achterscherm",
  // 		"hood": "Motorkap",
  // 		"window glass": "Ruit",
  // 		"taillight": "Achterlicht",
  // 		"headlight": "Koplamp",
  // 		"fog light": "Mistlamp",
  // 		"wheel": "Wiel",
  // 		"wheel rim": "Velg",
  // 		"license plate": "Kentekenplaat",
  // 		"You captured the wrong side. Please capture # again.": "Onjuiste foto. Maak een foto vanuit de juiste hoek voor # .",
  // 		"You captured the wrong side. You captured # in stead of # .": "Onjuiste foto. Er is een foto gemaakt van de # in plaats van # .",
  // 		"Front side": "Voorzijde",
  // 		"Front Left side": "Links voor",
  // 		"Left side": "Linkerzijde",
  // 		"Back Left side": "Links achter",
  // 		"Back side": "Achterzijde",
  // 		"Back Right side": "Rechts achter",
  // 		"Right side": "Rechterzijde",
  // 		"Front Right side": "Rechts voor"
  // 	}
  // },

  //   "intro-page": {
  //     image: "/images/car.png",
  //     title: "Welcome to Video Capture Application",
  //     "show-email-option": true,
  //     "email-text": "Enter your email to receive inspection report.",
  //     placeholder: "Email",
  //     "btn-text": "Proceed",
  //     "show-privacy-policy": false,
  //     "privacy-policy-type": "checkbox",
  //     "privacy-policy-text": "Do you accept the privacy policy",
  //     "privacy-policy-link": "https://inspektlabs.com/privacy-policy",
  //   },
  // "TimeOut":{
  //   "timeInMinutes": 15,
  //   "header-text": "Process Time Out",
  //   "body-text": "Your 15-minute session has ended. Refresh the link and finish the inspection within the next 15 minutes.",
  //   "btn-text": "Refresh Link"
  // }
  //   "instruction-page": {
  //     title: "Before you begin, please ensure the following.",
  //     image: "/images/car.png",
  //     instructions: [
  //       "There should be enough space (at least 5 ft / 150 cm) around the car to capture the 360 degree video.",
  //       "There is enough light in the area for a clear video.",
  //       "Your car is clean and dust free.",
  //       "Your device is connected to stable internet and camera is clean.",
  //     ],
  //     "btn-text": "Proceed",
  //   },
  //   "form-page": {
  //     btn: "#006cca",
  //     btnText: "Upload",
  //     alertImageType: "Please select a PDF or JPEG file",
  //     alertImageCorrupted: "file is Corrupted",
  //     impData: "Username",
  //     tag_mandatory: [
  //       "Username",
  //       "Status",
  //       "Role",
  //       "car type",
  //       "New car",
  //       "Age",
  //       "License",
  //       "Status-data",
  //       "Role-data",
  //     ],
  //     inputTag: {
  //       Username: "",
  //       Age: "",
  //     },
  // "textAreaTag": {
  //  Username: "",
  //       Age: "",
  // }
  //     radio: {
  //       Status: ["pickup", "Drop off"],
  //       Role: ["Normal", "Refueling", "illegal"],
  //     },
  //     checkbox: {
  //       "Status-data": ["pickup", "Drop off"],
  //       "Role-data": ["Normal", "Refueling", "illegal"],
  //     },
  //     select: {
  //       "car type": ["bmw", "audi", "tata", "mahindra"],
  //       "New car": ["bmw", "audi", "tata", "mahindra"],
  //     },
  //     documents: ["License", "Adhar Card", "Pan card"],
  //   },
  // ''
  //   "menu-page": {
  //     title: "Please click on the sections below start the inspection.",
  //     "mandatory-modal-text":
  //       "Please complete the following mandatory steps to continue:",
  //     "optional-modal-text":
  //       "Are you sure you want to continue, few optional steps are left?",
  //     "modal-btn-next": "Submit and Upload",
  //     "modal-btn-back": "Back to menu",
  //     "btn-text": "Submit and Upload",
  //     damageConfirmationPopup:
  //       "Comfirmation for No damage on car before submission",
  //     damageConfirmationPopupYesBtn: "Yes",
  //     damageConfirmationPopupNoBtn: "No",
  //   },
  //   "video-page": {
  //     guidanceMessages: {
  //       zoomedIn: "Please stand 2-3 feet away from the car.",
  //       farAway: "You are too far away from the car. Go a bit closer.",
  //       noDetection: "Please point your camera at the car.",
  //       speedHigh: "You are moving too fast! Please slow down.",
  //       abruptMovement: "Abrupt Movement detected !",
  //       lowLight: "Please take the video in proper lighting conditions.",
  //       initializingAngle:
  //         "Please point towards the car while the Angle is being calculated.",
  //       generic: "Continue going around the car at a steady pace.",
  //       notCovered: "View not covered properly.",
  //       complete: "Great! You've covered the car properly.",
  //     },
  //     region: {
  //       Front: "FRONT",
  //       "Front-Isometric-Left": "FRONT_ISOMETRIC_LEFT",
  //       "Side-Left": "SIDE_LEFT",
  //       "Rear-Isometric-Left": "REAR_ISOMETRIC_LEFT",
  //       Rear: "REAR",
  //       "Rear-Isometric-Right": "REAR_ISOMETRIC_RIGHT",
  //       "Side-Right": "SIDE_RIGHT",
  //       "Front-Isometric-Right": "FRONT_ISOMETRIC_RIGHT",
  //     },
  //     clientRegion: "eu or in",
  //     lowLightRegion: "Regions in Low Light:",
  //     "timeup-text": "Video can only be two minutes long.",
  //     "timeup-text-new": "Video can only be Seven minutes long.",
  //     "one-revolution": "You have completed one revolution around the car.",
  //     "missed-region-text": "Continue to capture missed regions:",
  //     "left-text": "Left",
  //     "right-text": "Right",
  //     "front-text": "Front",
  //     "rear-text": "Rear",
  //     "covered-text": "Green represents that this side is captured perfectly.",
  //     "notcovered-text":
  //       "Red represents that this side is not captured perfectly.",
  //     dataText:
  //       "Tap once on the red button to start recording. It might take a few seconds before recording begins.",
  //     settingsText:
  //       "<p>Please 'Allow' camera in Chrome / Safari settings. Click <a href = 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DAndroid'>here</a> for Chrome instructions & click <a href = 'https://support.apple.com/en-in/guide/safari/ibrwe2159f50/mac'>here</a> for Safari instructions</p>",
  //     loadingText:
  //       "Model initialising .... Recording will start in a few seconds",
  //     completeText:
  //       "All sections have been captured. You can move further to other steps",
  //     incompleteText:
  //       "You can move further to other steps to complete the process.",
  //     btnText: "Next",
  //     rotateText: "Please turn the screen to landscape mode",
  //     backText: "Go Back",
  //     stopText:
  //       "All regions of car are covered. Do you want to continue recording?",
  //     continueText: "Continue",
  //     stopRecordingText: "Stop recording",

  //     "restrictUserIfallNotGreen": false,
  //  "videoTimeoutRefreshLink": {
  // 			"time-limitup-text-1": "Due to our strict 7-minute policy, all videos and photos captured so far will be deleted.",
  // 			"time-limitup-text-2": "You will need to restart the inspection process.",
  // 			"restartText_btn": "Restart inspection"
  // 		} (configurable for few clients),
  //  "videoNudges": {
  //   "Point your camera to": "Point your camera to",
  //   "back_bumper": "back bumper",
  //   "back_door": "back door",
  //   "back_glass": "back glass",
  //   "dicky": "dicky",
  //   "fender": "fender",
  //   "front_bumper": "front bumper",
  //   "front_door": "front door",
  //   "front_glass": "front glass",
  //   "headlight": "headlight",
  //   "hood": "hood",
  //   "qtr_panel": "qtr panel",
  //   "running_board": "running board",
  //   "side_view_mirror": "side view mirror",
  //   "taillight": "taillight",
  //   "wheel": "wheel",
  //   "window_glass": "window glass"
  // }

  //   },
  // "vin-video": {
  //   "instruction" : "Please place the VIN inside the white-bordered box until you receive a pop-up message",
  //   "locate vin inst.": "Locate the VIN in your car and then press the 'Continue' button to start recording the VIN",
  //   "continue-btn": "Continue",
  //   "vinDetected message": "VIN is Detected",
  //   "vinNotDetected message": "VIN is not Detected",
  //   "retryBtnText": "Retry",
  //   "contnueExitBtnText": "Continue",
  // }
  //   "damage-page": {
  //     "zoomIn-menu-image": "/images/zoomIn.png",
  //     "zoomOut-menu-image": "/images/zoomOut.png",
  //     "menu-title": "How damage capture works?",
  //     "zoomIn-menu-title": "Capture zoomed in view",
  //     "zoomIn-menu-text":
  //       "Capture from 0.5-1 feet away from the damaged part to identify the damage.",
  //     "zoomOut-menu-title": "Capture the zoomed out view",
  //     "zoomOut-menu-text":
  //       "Capture from 3-4 feet away from the car to identify damaged part.",
  //     "menu-btn-text": "Start",
  //     "back-btn-text": "Go back",
  //     "select-screen-title": "Capture Damages",
  //     "zoomIn-select-text": "Zoom In",
  //     "zoomOut-select-text": "Zoom Out",
  //     "more-btn": "Take more Damage",
  //     "end-btn": "End and Submit",
  //     captured: "Captured",
  //     pending: "Pending",
  //     endPopupMsg: "Are you sure you want to end ?",
  //     endPopupYesBtn: "Yes",
  //     endPopupNoBtn: "No",
  //   },
  //   "multiple-image-page": {
  //     "next-btn-text": "Submit",
  //     "back-btn-text": "Go back",
  //     "modal-btn-next": "Submit",
  //     "mandatory-modal-text":
  //       "Please complete the following mandatory steps to continue:",
  //     "optional-modal-text":
  //       "Are you sure you want to continue, few optional steps are left?",
  //     "back-modal-text":
  //       "You can not go back. Please press submit to continue.",
  //   },
  //   "rotate-page": {
  //     text: "Please rotate the device to landscape",
  //     "rotateBtnTxt": "Click to rotate",
  //   },
  //   "success-page": {
  //     image: "/images/final.gif",
  //     text1: "Thank you. Your video has been successfully uploaded.",
  //     text2: "You can now close this tab",
  //     "support-email-text": "Please contact for any queries at",
  //     "support-email": "info@inspektlabs.com",
  //     "inspection-id": "Inspection ID",
  //     "ImageUploadingStatusTxt": "Images uploading",
  //     "uploading-text": "Uploading Images",
  //     "tab-text": "Please donot close the tab or refresh the page",
  //     "rating-popup": true,
  // 	   "rating-heading-txt": "Please rate your experience",
  // 	   "rating-comment-txt": "  Comment Here..",
  // 	   "rating-btn-text": "Submit"

  //   },
  //   errors: {
  //     connection: "connection",
  //     used: "This URL has already been used.",
  //     invalid: "This URL has been expired.",
  //     services:
  //       "Unable to connect to the services. Please refresh and retry again",
  //     "no-internet": "No Internet connection",
  //     "slow-internet": "Slow connection. Please switch to better connection.",
  //     invalid_personaId: "Invalid PersonaId",
  //     motion_sensor_samsung : "Gyro permission is denied \nAt bottom right corner, click on three horizontal lines (menu) > Settings > Sites and Downloads > Site permissions > Motion sensor > Switch it on",
  //     motion_sensor_not_support_samsung : "Gyro permission is denied \nAt bottom right corner, click on three horizontal lines (menu) > Settings > Sites and Downloads > Site permissions > Motion sensor > Switch it on",
  //     camera_error_samsung : "Camera is already in use by another application or tab. or blocked \nAt bottom right corner, click on three horizontal lines of Samsung menu > Settings > Sites and Downloads > Site Permissions > Camera > switch it on \n blocked > https://superapp.inspektlabs.com > Allow. \n (Refresh the link and allow all Permission)",
  //     restrictionforLocationPermissionTxtForSamsung : "Location permission is denied. Please enable it for Samsung browser",
  //     motion_sensor_edge : "Gyro permission is denied \nAt bottom right corner, click on three horizontal lines (menu) > Settings > Privacy and Security > Site Settings > Motion sensor > Switch it on",
  //     motion_sensor_not_support_edge : "Gyro permission is denied \nAt bottom right corner, click on three horizontal lines (menu) > Settings > Privacy and Security > Site Settings > Motion sensor > Switch it on",
  //     camera_error_edge : "Camera is already in use by another application or tab. or blocked \nAt bottom right corner, click on three horizontal lines > Settings > Privacy and Security > Site Settings > Camera > switch it on \n blocked > https://superapp.inspektlabs.com > Allow. \n (Refresh the link and allow all Permission)",
  //     restrictionforLocationPermissionTxtForEdge : "Location permission is denied. Please enable it for Edge browser"
  //     camera_error: "Camera is already in use by another application or tab.",
  //     motion_sensor_safari:
  //       "Motion Sensor permission denied.\nSetting > Safari > Clear History and Website Data > keep Tabs. (Refresh the link and allow all Permission)",
  //     motion_sensor_not_support_safari: "Gyroscope API is not supported on iOS",
  //     motion_sensor_chrome:
  //       "Gyro permission is denied \nAt right top corner, click to three dot of chrome > settings > site settings > motion sensor > switch it on",
  //     motion_sensor_not_support_chrome:
  //       "Gyro permission is denied \nAt right top corner, click to three dot of chrome > settings > site settings > motion sensor > switch it on",
  //     desktop_view_enabled:
  //       "Desktop View is enabled.\nIos -> Setting > Safari > Request Desktop Website > All Websites (Turn it Off). refresh the link\nAndroid -> Chrome Setting > Site settings > Desktop site (Turn it off). Refresh the link",
  //     ios_chrome_error: "Ios does not support Chrome Please open it on Safari",
  //     android_samsung_error:
  //       "Application is not supported on Samasung browser on Android. Please open on Chrome.",
  // "permissonHeaderText":"Please allow permissions",
  // "cameraHeaderText":"Camera Permission",
  // "cameraRequirementText": "For capturing photo / video of car"
  // "gyroHeaderText":"Gyro Permission",
  // "gyroRequirementText":"For detecting phone position WRT car",
  // "locationHeaderText":"Location Permission",
  // "locationRequirementText":"To know the location of your inspection",
  // "locationHeaderOptionalText":"Optional",
  // "alowBtnText":"Allow",
  //   },
  //   qr: {
  //     scan: "Scan-Text",
  //     "rescan-btn-text": "Rescan QR",
  //     "capture-text": "Capture slip",
  //     "proceed-text": "proceed",
  //   },
  //   windshieldDamage: {
  //     imageOne:
  //       "https://remarkable-bienenstitch-d013ae.netlify.app/Picture2.png",
  //     imageTwo:
  //       "https://remarkable-bienenstitch-d013ae.netlify.app/Picture1.png",
  //     imageThree: "",
  //     instOne: "Step 1 – fit the windscreen inside the wireframe",
  //     instTwo: "Step 2 – drag-and-drop covering glass damage",
  //     instThree: "Step 3 – take close-up photo identifying the damage",
  //     cameraText: "Step 1 – fit the windscreen perfectly inside the wireframe",
  //     reSizeText: "Step 2 – drag the box to highlight glass",
  //     stepTextOne: "Step 1 & Step 2",
  //     stepTextTwo: "Step 3",
  //   },
  //   colors: {
  //     nav: "#151515",
  //     "incomplete-block": "#d1d1d1",
  //     "complete-block": "#13da0e",
  //     btn: "#006cca",
  //   },
  //   font: {
  //     family: "aerial",
  //     "header-size": "4px",
  //     "para-size": "10px",
  //   },
  //   blocks: [
  // {
  // 	"name": "Selfie",
  // 	"id": "Selfie",
  // 	"tag": "Selfie",
  // 	"link": "https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/app-icon/selfie.svg",
  // 	"subSections": false,
  // 	"mandatory": false,
  // 	"description": "This is License Plate",
  // 	"values": []
  // }
  // {
  // "name": "Video Recorder",
  // "id": "Video Recorder",
  // "audioRecording": true,
  // "cameraInstruction": "Click red button to start recording",
  // "videoTimer": 10,
  // "tag": "Odometer",
  // "link": "https://icons8.com/icon/IiSvPRyWmCsJ/video-recorder",
  // "subSections": false,
  // "mandatory": false,
  // "description": "This is Odometer",
  // "values": []
  // },
  // {
  //       name: "New Video Capture",
  //       id: "Video Capture",
  //       tag: "Video Capture",
  //       link: "/images/360.png",
  //       subSections: false,
  //       mandatory: false,
  //       description: "This is 360 video capture",
  //       values: [],
  //     },
  //     {
  //       name: "Video Capture",
  //       id: "Video Capture",
  //       tag: "Video Capture",
  //       link: "/images/360.png",
  //       subSections: false,
  //       mandatory: false,
  //       description: "This is 360 video capture",
  //       values: [],
  //     },
  //     {
  //       name: "",
  //       tag: "",
  //       id: "360 Image Module",
  //       link: "/images/360.png",
  //       subSections: true,
  //       description: "This is wheel module",
  //       mandatory: true,
  //       values: [
  //         {
  //           name: "Front",
  //           image: "/images/front.png",
  //           mandatory: true,
  //           cameraInstruction: "",

  //         },
  //         {
  //           name: "Front left",
  //           image: "/images/front-left.png",
  //           mandatory: true,
  //           cameraInstruction: "",

  //         },
  //         {
  //           name: "Left",
  //           image: "/images/left.png",
  //           mandatory: true,
  //           cameraInstruction: "",

  //         },
  //         {
  //           name: "Rear Left",
  //           image: "/images/back-left.png",
  //           mandatory: true,
  //           cameraInstruction: "",

  //         },
  //         {
  //           name: "Rear",
  //           image: "/images/back.png",
  //           mandatory: true,
  //         },
  //         {
  //           name: "Rear Right",
  //           image: "/images/back-right.png",
  //           mandatory: true,
  //         },
  //         {
  //           name: "Right",
  //           image: "/images/right.png",
  //           mandatory: true,
  //         },
  //         {
  //           name: "Front Right",
  //           image: "/images/front-right.png",
  //           mandatory: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "",
  //       tag: "",
  //       id: "Odometer",
  //       link: "/images/odo.png",
  //       subSections: false,
  //       mandatory: true,
  //       description: "This is odometer",
  //     cameraInstruction: "",
  //       values: [],
  //     },
  //     {
  //       name: "",
  //       id: "VIN",
  //       tag: "",
  //       link: "/images/vin.png",
  //       subSections: false,
  //       mandatory: true,
  //       description: "This is VIN",
  //     cameraInstruction: "",

  //       values: [],
  //     },
  //     {
  //       name: "damage",
  //       tag: "",
  //       id: "damage",
  //       count: 10,
  //       link: "/images/damage.jpg",
  //       subSections: false,
  //       mandatory: false,
  //       cameraInstructionZoomIn: "",
  //       cameraInstructionZoomout: "",
  //       description: "This is zoom IN/zoom OUT",
  //       values: [],
  //     },
  //     {
  //       name: "zeroapp",
  //       id: "Zero App",
  //       tag: "",
  //       link: "/images/360.png",
  //       subSections: true,
  //       description: "This is wheel module",
  //       mandatory: true,
  //       values: [
  //         {
  //           name: "capture1",
  //           image: "",
  //           mandatory: true,
  //           tag: "",
  //         },
  //         {
  //           name: "capture2",
  //           image: "",
  //           mandatory: true,
  //         },
  //         {
  //           name: "capture3",
  //           image: "",
  //           mandatory: true,
  //           tag: "",
  //         },
  //         {
  //           name: "capture4",
  //           image: "",
  //           mandatory: true,
  //         },
  //         {
  //           name: "capture5",
  //           image: "",
  //           mandatory: true,
  //           tag: "",
  //         },
  //         {
  //           name: "capture6",
  //           image: "",
  //           mandatory: true,
  //         },
  //         {
  //           name: "capture7",
  //           image: "",
  //           mandatory: true,
  //           tag: "",
  //         },
  //         {
  //           name: "capture8",
  //           image: "",
  //           mandatory: true,
  //         },
  //         {
  //           name: "capture9",
  //           image: "",
  //           mandatory: true,
  //           tag: "",
  //         },
  //         {
  //           name: "capture10",
  //           image: "",
  //           mandatory: true,
  //         },
  //         {
  //           name: "capture11",
  //           image: "",
  //           mandatory: true,
  //           tag: "",
  //         },
  //         {
  //           name: "capture12",
  //           image: "",
  //           mandatory: true,
  //         },
  //       ],
  //     },
  //     {
  //       name: "QR",
  //       id: "QR",
  //       tag: "",
  //       link: "/images/damage.jpg",
  //       capture: true,
  //       subSections: false,
  //       mandatory: false,
  //       description: "This is QR scanner",
  //       values: [],
  //     },
  //     {
  //       name: "Windshield Damage",
  //       tag: "",
  //       id: "windShield Damage",
  //       count: 10,
  //       link: "https://incapture.inspektlabs.com/assets/img/windshield.svg",
  //       subSections: true,
  //       mandatory: false,
  //       description: "This is zoom IN/zoom OUT",
  //       values: [],
  //     },
  //      {
    //       name: "ImageUpload",
    //       tag: "uploadImage",
    //       id: "Image Upload",
    //       count: 10,
    //       link: "https://incapture.inspektlabs.com/assets/img/windshield.svg",
    //       subSections: true,
    //       mandatory: false,
    //       description: "This is Image Upload",
    //       values: [],
    //     },
  //   ],
  // };

  const checkToken = async () => {
    console.log("sdfsdfsdfsdf")
    if (userToken === undefined) {
      setErrorMessage("Unique token is missing. Please use a valid URL");
      setScreen("error");
    } else {
      console.log("abcds")
      const res = await makeRequest("/checkToken", { token: userToken });
      console.log("jjklkklll")
      const data = await res.json();
      if (data.status === "error") {
        if (data.errorType === "missing") {
          setErrorMessage("Unique token is invalid. Please use a valid URL");
        } else if (data.errorType === "used") {
          setErrorMessage(data.configData["errors"]["used"]);
        } else if (data.errorType === "wrongpath") {
          setErrorMessage(
            Object.keys("errors").includes("invalid_personaId")
              ? data.configData["errors"]["invalid_personaId"]
              : "Invalid Persona Id"
          );
        } else {
          setErrorMessage(data.configData["errors"]["invalid"]);
        }
        setScreen("error");
      } else {
        setLogging(data.setLogStatus);
        console.log("token verification time", startdates - Date.now());
        startdates = Date.now();
        const userAgent = window.navigator.userAgent;
        let browserName;
        let browserVersion;

        if (isIOS && navigator.userAgent.includes("CriOS")) {
          browserName = "Google Chrome";
          browserVersion = userAgent.match(/CriOS\/(\S+)/) ? userAgent.match(/CriOS\/(\S+)/)[1] : null;
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
          browserName = "Apple Safari";
          browserVersion = userAgent.match(/Version\/(\S+)/)[1] || null;
        } else if (isAndroid && navigator.userAgent.includes("Samsung")) {
          browserName = "Samsung Browser";
          browserVersion =
            userAgent.match(/SamsungBrowser\/(\d+\.\d+)/)[1] || null;
        } else if (isAndroid && navigator.userAgent.includes("Edg")) {
          browserName = "Edge Browser";
          var version =
            userAgent.match(/Edg\/(\d+\.\d+\.\d+\.\d+)/) ||
            userAgent.match(/Edge\/(\d+\.\d+\.\d+\.\d+)/) ||
            userAgent.match(/EdgA\/(\d+\.\d+\.\d+\.\d+)/);
          browserVersion = version ? version[1] : null;
        } else if (isAndroid && !navigator.userAgent.includes("Chrome")) {
          browserName = "other";
          browserVersion = "N/A";
        } else if (
          isAndroid &&
          !navigator.userAgent.split(")")[2].split("/").length !== 3
        ) {
          browserName = "Google Chrome";
          browserVersion = userAgent.match(/Chrome\/(\S+)/)[1] || null;
        } else {
          browserName = "other";
          browserVersion = "N/A";
        }

        let moduleSelect = false;
        let allModule = [];
        if (
          Object.keys(data.configData["font"]).includes("url") &&
          data.configData["font"]["url"]
        ) {
          loadCustomFont(data.configData["font"]["url"]);
        } else {
          loadCustomFont(
            "https://superapp-images-inspektlabs.s3.eu-central-1.amazonaws.com/Font_style/default/d-din.regular+(1).ttf"
          );
        }
        if(Object.keys(data.configData["video-page"]).includes("restrictUserIfallNotGreen")){
          setRestrictUserIfallNotGreen(data.configData["video-page"]["restrictUserIfallNotGreen"])
        }
        data.configData["blocks"].map((e) => {
          allModule.push(e.tag);
          if (
            e["name"] === "Video Capture" ||
            e["name"] === "New Video Capture"
          ) {
            startModel();
            moduleSelect = true;
          } if (
            e["name"] === "Windshield Damage"
          ) {
            windshiledModuleCount = true;
          } if (
            e["name"] === "damage"
          ) {
            damageModuleCount = true;
          }
        });
        try {
          const res = await makeRequest("/setRetake", { token: userToken, resumeFlow: data.configData["resumeFlow"] });
          if (res.ok) {
            console.log("initialize inspection id", startdates - Date.now());
            startdates = Date.now();
            let retakeData = await res.json();
            console.log(retakeData)
            SetRetake_inspectionId = retakeData.data;
            reOpen = retakeData.reOpen ? retakeData.reOpen : false;
            setRetake(retakeData.retake);
            initializeretake(retakeData.retake);
            if (retakeData.status === "error") {
              setErrorMessage(config["errors"]["services"]);
              setScreen("error");
              // ReactGA.event({
              //   category: "Unable to create inspectionId",
              //   action: "Unable to create inspectionId",
              // });
              // console.log("Unable to create inspectionId")
            } else {
              const userAgent = navigator.userAgent;
              const md = new MobileDetect(userAgent);
              let logData = {
                position: 0,
                inspection_id: retakeData.data,
                client_id: data.clientId,
                token: userToken,
                browser_name: browserName,
                browser_version: browserVersion,
                os: isIOS ? "ios" : isAndroid && "android",
                app: moduleSelect ? "video" : "image",
                total_module: allModule.join(", "),
                user_agent: JSON.stringify(navigator.userAgent),
                mobile_detect: JSON.stringify(md),
                module_count: allModule.length.toString(),
                case_id: data.caseId,
              };

              //checking if email is saved or not
              if (Object.keys(data.configData).includes("resumeFlow") && reOpen) {
                try {
                  let makereq = await makeRequest(`/setResumeFlowEmail/${SetRetake_inspectionId}/GET`);
                  if (makereq.ok) {
                    const emailData = await makereq.json();
                    let fetchEmail = emailData.data ? emailData.data : "";
                    console.log("fetch email ::::::::::::::::    ", fetchEmail)
                    if (fetchEmail != "") {
                      resumeFlowEmailAllow = false;
                      console.log("skip email part")
                      initializeEmail(fetchEmail);
                      setUserEmail(fetchEmail);
                    }
                  }
                }
                catch (err) {
                  console.log("fetch email redis error     ", err);
                }
              }

              //check if some photo is uploaded or not
              if (Object.keys(data.configData).includes("resumeFlow") && reOpen) {
                try {
                  let makereq = await makeRequest(`/fetchResumeFlow/${SetRetake_inspectionId}/GET`);
                  if (makereq.ok) {
                    const allowFetchData = await makereq.json();
                    fetchDataFromRedis = allowFetchData.flag ? allowFetchData.flag : false;
                    setRedisFlowSetFirstTime(fetchDataFromRedis);
                  }
                }
                catch (err) {
                  console.log("fetch redis flow error");
                  fetchDataFromRedis = false;
                  setRedisFlowSetFirstTime(fetchDataFromRedis);
                }
              }

              setConfig(data.configData);
              setCaseId(data.caseId);
              sendLogData(logData, data, retakeData.data);
              initializeInspectionId(retakeData.data);
              setInspectionId(retakeData.data);
              setInspectionID(retakeData.data)
            }

            // res.json().then((res1) => {
            //   setRetake(res1.retake);
            //   initializeretake(res1.retake);
            //   if (res1.status === "error") {
            //     setErrorMessage(config["errors"]["services"]);
            //     setScreen("error");
            //     // ReactGA.event({
            //     //   category: "Unable to create inspectionId",
            //     //   action: "Unable to create inspectionId",
            //     // });
            //     // console.log("Unable to create inspectionId")
            //   } else {
            //     const userAgent = navigator.userAgent;
            //     const md = new MobileDetect(userAgent);
            //     let logData = {
            //       position: 0,
            //       inspection_id: res1.data,
            //       client_id: data.clientId,
            //       token: userToken,
            //       browser_name: browserName,
            //       browser_version: browserVersion,
            //       os: isIOS ? "ios" : isAndroid && "android",
            //       app: moduleSelect ? "video" : "image",
            //       total_module: allModule.join(", "),
            //       user_agent: JSON.stringify(navigator.userAgent),
            //       mobile_detect: JSON.stringify(md),
            //       module_count: allModule.length.toString(),
            //       case_id: data.caseId,
            //     };
            //     setCaseId(data.caseId);
            //     sendLogData(logData, data, res1.data);
            //     initializeInspectionId(res1.data);
            //     setInspectionId(res1.data);
            //     console.log("loading insop:::::::   ", res1.data)
            //     setInspectionID(res1.data)
            //   }
            // });
          } else {
            // console.log("Unable to create inspectionId")
            // ReactGA.event({
            //   category: "Unable to create inspectionId",
            //   action: "Unable to create inspectionId",
            // });
            setErrorMessage(config["errors"]["services"]);
            setScreen("error");
            return;
          }
        } catch (err) {
          setErrorMessage(config["errors"]["services"]);
          setScreen("error");
          console.log(err);
          return;
        }
        setClientId(data.clientId);
        initializeToken(userToken);
        //In case of success
        setToken(userToken);
        setConfig(data.configData);

        //setting resume flow
        if (Object.keys(data.configData).includes("resumeFlow")) {
          console.log("allowing resume flow")
          setResumeFlowAllow(true);
        }

        if (Object.keys(data.configData).includes("resumeFlow") && reOpen && fetchDataFromRedis) {
          console.log("dc")
          const retakeRedis = await makeRequest(`/redisConfigValue/${SetRetake_inspectionId}/GET`);
          const countRedis = await makeRequest(`/redisCountValue/${SetRetake_inspectionId}/GET`);
          if (windshiledModuleCount) {
            const windshiledCount = await makeRequest(`/windshieldRedisCount/${SetRetake_inspectionId}/GET`);
            if (windshiledCount.ok) {
              const countResult = await windshiledCount.json();
              setWindShieldDamageCount(countResult.count);
            }
          }
          console.log("damage calling after this", damageModuleCount)
          if (damageModuleCount) {
            console.log("insiode dfamage kehdiejhdi")
            const damageCount = await makeRequest(`/damageRedisCount/${SetRetake_inspectionId}/GET`);
            console.log(damageCount, " ::::::::::::::::::::::::::::")
            if (damageCount.ok) {
              const countResult = await damageCount.json();
              console.log("damage count ::::::::::::::::   ", countResult.count);
              setCount(countResult.count);
              setDamageCount(countResult.count);
              setCurrentDamage(countResult.count);
            }
          }
          if (countRedis.ok) {
            console.log("inside ok coumnt redis");
            const countResult = await countRedis.json();
            console.log("count value is ::::::    ", countResult)
            setResumeFlowCount(countResult.count);
          }
          if (retakeRedis.ok) {
            console.log("retake reduis inside::::::  ", retakeRedis)
            let res1 = await retakeRedis.json();
            setCondition(res1.conditions);
            setMandatory(res1.mandatoryConditions);
            setSubMandatory(res1.subMandatoryConditions);
            setSubCondition(res1.subConditions);

            console.log(res1.conditions, res1.mandatoryConditions, res1.subMandatoryConditions, res1.subConditions)

            //resumeFlow
            setConditionResume(res1.conditions);
            setMandatoryResume(res1.mandatoryConditions);
            setSubMandatoryResume(res1.subMandatoryConditions);
            setSubConditionResume(res1.subConditions);
          }
        }
        else {
          console.log("no resume")
          data.configData["blocks"].map((item) => {
            finalConditions[item.id] = "pending";
            if (item.mandatory) {
              mandatoryConditions[item.id] = "pending";
            }
            if (item.subSections) {
              subMandatoryConditions[item.id] = {};
              finalSubConditions[item.id] = {};
              item.values.map((item2) => {
                if (item2.mandatory) {
                  subMandatoryConditions[item.id][item2.name] = "pending";
                }
                finalSubConditions[item.id][item2.name] = "pending";
              });
            }
          });
          setCondition(finalConditions);
          setMandatory(mandatoryConditions);
          setSubMandatory(subMandatoryConditions);
          setSubCondition(finalSubConditions);

          //resumeFlow
          if (Object.keys(data.configData).includes("resumeFlow")) {
            setConditionResume(finalConditions);
            setMandatoryResume(mandatoryConditions);
            setSubMandatoryResume(subMandatoryConditions);
            setSubConditionResume(finalSubConditions);
          }
        }
      }
    }
  };
  const sendLogData = async (value, value2, insp) => {
    if (value2 !== null) {
      userLogs(value);
      patLogs("Entered to loading page", insp);
      userLogs({
        position: 8,
        last_page: "loading page",
        inspectionId: insp,
      });
      endDateLog = new Date().getTime();
      userLogs({
        position: 12,
        loading_timer: `${endDateLog - startDateLog} ms`,
        inspectionId: insp,
      });
      if (window.ReactNativeWebView || navigator.userAgent.indexOf("wv") > -1) {
        if (
          Object.keys(value2.configData).includes("rejectionOption") &&
          value2.configData["rejectionOption"]
        ) {
          setScreen("rejection");
        } else if (value2.configData["email"]) {
          if (resumeFlowEmailAllow) {
            console.log("not skipping email")
            patLogs("Entered to email page", inspectionId);
            userLogs({ position: 8, last_page: "email page", inspectionId });
            setScreen("email");
          }
          else {
            if (fetchDataFromRedis) {
              console.log("skipping email and entering to restart page")
              patLogs("Skip Email page and Entered to restart page", inspectionId);
              userLogs({ position: 8, last_page: "restart page", inspectionId });
              setScreen("restart");
            }
            else {
              console.log("skipping email and entering to instruction page page")
              patLogs("Skip Email page and Entered to Instruction page page", inspectionId);
              userLogs({ position: 8, last_page: "instruction page", inspectionId });
              setScreen("instructions");
            }
          }
        } else {
          patLogs("Entered to Instructions page", inspectionId);
          userLogs({ position: 8, last_page: "instruction page", inspectionId });
          setScreen("instructions");
        }
      }
      else if (isAndroid && !navigator.userAgent.includes("Chrome") && !navigator.userAgent.includes("Samsung") && !navigator.userAgent.includes("Edg")) {
        setPhone("android");
        setScreen("error");
      } else if (
        isAndroid &&
        navigator.userAgent.split(")")[2].split("/").length !== 3 &&
        !navigator.userAgent.includes("Samsung") &&
        !navigator.userAgent.includes("Edg")
      ) {
        setPhone("android");
        setScreen("error");
      }
      else if (
        Object.keys(value2.configData).includes("rejectionOption") &&
        value2.configData["rejectionOption"]
      ) {
        setScreen("rejection");
      } else if (value2.configData["email"]) {
        console.log("adding starting logs", startdates - Date.now());
        if (resumeFlowEmailAllow) {
          console.log(" not skipping email 1")
          patLogs("Entered to email page", inspectionId);
          userLogs({ position: 8, last_page: "email page", inspectionId });
          setScreen("email");
        }
        else {
          if (fetchDataFromRedis) {
            console.log("skipping email and entering to restart page")
            patLogs("Skip Email page and Entered to restart page", inspectionId);
            userLogs({ position: 8, last_page: "restart page", inspectionId });
            setScreen("restart");
          }
          else {
            console.log("skipping email and entering to instruction page page")
            patLogs("Skip Email page and Entered to Instruction page page", inspectionId);
            userLogs({ position: 8, last_page: "instruction page", inspectionId });
            setScreen("instructions");
          }
        }
        sendCallback(value, value2.configData, insp);
      } else {
        patLogs("Entered to Instructions page", inspectionId);
        userLogs({ position: 8, last_page: "instruction page", inspectionId });
        setScreen("instructions");
        sendCallback(value, value2.configData, insp);
      }
    }
  };
  const sendCallback = (value, configData, insp) => {
    if (
      Object.keys(configData).includes("vmgFLowType") &&
      Object.keys(configData).includes("vmgUrl")
    ) {
      fetch(configData["vmgUrl"], {
        method: "POST",
        headers:
          configData["authorization"] === "" ||
            configData["authorization"] === null
            ? {
              "Content-Type": "application/json",
            }
            : {
              "Content-Type": "application/json",
              Authorization: configData["authorization"],
            },
        body: JSON.stringify({
          caseId: value.case_id,
          inspectionId: insp,
          webAppToken: value.token,
          status: "Url was opened",
          code: 1,
          // "imageUrl": urls
        }),
      })
        .then((response) => {
          // Handle response
          console.log(response);
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    }
  };
  useEffect(() => {
    checkToken();
    // window.addEventListener("beforeunload", (event) => {
    //   event.preventDefault();
    //   sendLogData(
    //     { position: 4, refreshed: "yes", inspectionId },
    //     null,
    //     inspectionId
    //   );
    //   // Sentry.setTag("last_page", currentPage);
    //   let data = {
    //     token: userToken,
    //     clientId,
    //     currentPage,
    //     inspectionId,
    //     page: "closed",
    //   };
    //   setLog(`${userToken} refreshed the app`);
    //   // console.log(clientUniqueIdentity)
    //   // console.log(`${userToken} closed the app`)

    //   return (event.returnValue =
    //     "Are you sure you ant to close the application?");
    // });

    // let navigationEntries = performance.getEntriesByType("navigation");

    // if (navigationEntries && navigationEntries.length > 0) {
    //   let navigationEntry = navigationEntries[0];

    //   if (navigationEntry.type === "reload") {
    //     let data = {
    //       token: userToken,
    //       clientId,
    //       currentPage,
    //       inspectionId,
    //       page: "refreshed",
    //     };
    //     // Sentry.setTag("last_page", currentPage);
    //     console.log(data);
    //     setLog(`${userToken} refreshed the app`);
    //     // console.log(clientUniqueIdentity)
    //     // console.log('This page was refreshed');
    //   }
    // } else {
    //   console.log(
    //     "Navigation Timing Level 2 API not supported or no navigation entry found"
    //   );
    // }

    // return() => {
    //     window.removeEventListener("beforeunload", (event)=>{
    //         event.preventDefault()
    //         setLog(`${userToken} refreshed the app`)
    //         console.log(`${userToken} refreshed the app`)
    //         return event.returnValue = "Are you sure you ant to close the application?"
    //     })
    // }
  }, []);

  return (
    <div className="result-wait">
      <img src={loading}></img>
      <h3>Verifying Token ...</h3>
    </div>
  );
};

export default Loading;
